.u-textCenter {
  text-align: center;
}

.u-textLeft {
  text-align: left;
}

.u-textRight {
  text-align: right;
}

.u-textJustify {
  text-align: justify;
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textLowercase {
  text-transform: lowercase;
}

.u-textCapitalize {
  text-transform: capitalize;
}

.u-paddingLeft-40 {
  padding-left: 40px;
}

.u-marginBottom-40 {
  margin-bottom: 40px;
}

.u-fontSize-138 {
  font-size: 1.38em;
}

.u-verticalAlign-middle {
  vertical-align: middle;
}

.u-verticalAlign-bottom {
  vertical-align: bottom;
}

.u-textDecoration-bold {
  font-weight: bold;
}

.u-textDecoration-lineThrough {
  text-decoration: line-through;
}

.u-opacity-30 {
  opacity: 0.3;
}

.u-opacity-50 {
  opacity: 0.5;
}

.u-opacity-80 {
  opacity: 0.8;
}

.u-overflow-auto {
  overflow: auto;
}

.u-borderRight-standard {
  border-right: 1px solid $ttc-grey-100;
}

.u-border-teal {
  border: 1px solid $ttc-teal;
}

.u-borderBottom-standard {
  border-bottom: 1px solid $ttc-grey-100;
}

.u-showScrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 2px;
}

.u-showScrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

.u-hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.u-hideScrollBar::-webkit-scrollbar {
  display: none;
}

.u-hover-container {
  .u-hover-appearOnHover {
    width: 0;
    overflow: hidden;

    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      width: auto;
    }
  }

  &:hover {
    .u-hover-appearOnHover {
      width: auto;
    }
  }
}

.u-button-box-shadow {
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 2px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14);
}
